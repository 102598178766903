<template>
  <div class="ThirdPartySettings_I">
    <div class="headNavs">
      <div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect_top">
          <el-menu-item index="1">公众号</el-menu-item>
          <el-menu-item index="2">小程序</el-menu-item>
          <el-menu-item index="3">支付宝</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails">说明：包含公众号、小程序、支付宝</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiQuery" @click="headerEdit()"><i class="el-icon-plus"></i>编辑</div>
      </div>
      <div v-if="isShow == 1">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>APPID</div>
          <div>{{wxapplist.app_id}}</div>
        </div>
        <div class="ToConfigure">
          <div>AECRET</div>
          <div>{{wxapplist.app_secret}}</div>
        </div>
        <div class="ToConfigure">
          <div>支付商户号</div>
          <div>{{wxapplist.mch_id}}</div>
        </div>
        <div class="ToConfigure">
          <div>商户秘钥</div>
          <div>{{wxapplist.key}}</div>
        </div>
        <div class="ToConfigure">
          <div>支付cert证书</div>
          <div>{{wxapplist.cert_client}}</div>
        </div>
        <div class="ToConfigure">
          <div>支付key证书</div>
          <div>{{wxapplist.cert_key}}</div>
        </div>
      </div>
      <div v-if="isShow == 2">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>APPID</div>
          <div>{{wechatlist.app_id}}</div>
        </div>
        <div class="ToConfigure">
          <div>AECRET</div>
          <div>{{wechatlist.app_secret}}</div>
        </div>
        <div class="ToConfigure">
          <div>支付商户号</div>
          <div>{{wechatlist.mch_id}}</div>
        </div>
        <div class="ToConfigure">
          <div>商户秘钥</div>
          <div>{{wechatlist.key}}</div>
        </div>
        <div class="ToConfigure">
          <div>支付cert证书</div>
          <div>{{wechatlist.cert_client}}</div>
        </div>
        <div class="ToConfigure">
          <div>支付key证书</div>
          <div>{{wechatlist.cert_key}}</div>
        </div>
      </div>
      <div v-if="isShow == 3">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>APPID</div>
          <div>{{wxapplist.app_id}}</div>
        </div>
        <div class="ToConfigure">
          <div>支付宝公钥</div>
          <div>{{wxapplist.app_secret}}</div>
        </div>
        <div class="ToConfigure">
          <div>开发者私钥</div>
          <div>{{wxapplist.mch_id}}</div>
        </div>
      </div>
    </div>

    <div class="show" v-if="editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="editPanel = false"><i class="el-icon-close"></i></div>
        </div>
        <div class="headNavs" style="padding:0 26px">
          <el-menu
            :default-active="acndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect2">
            <el-menu-item index="1">公众号</el-menu-item>
            <el-menu-item index="2">小程序</el-menu-item>
            <el-menu-item index="3">支付宝</el-menu-item>
          </el-menu>
        </div>
        <el-form :model="Row" :rules="rules" ref="ruleFormRef">
          <div class="EditNewForm" v-if="is_info == 1">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>APPID</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wxapp.app_id" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>AECRET</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wxapp.app_secret" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>支付商户号</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wxapp.mch_id" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>商户秘钥</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wxapp.key" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>支付cert证书</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wxapp.cert_client" placeholder="请填写名称" style="width:77%"></el-input>
                <el-upload
                  style="width: 14%;float: right;margin: 4px 30px 0 0;"
                  :action="upload"
                  :headers="heads"
                  :on-success="UP_Success4">
                  <el-button size="small" type="primary">选择文件</el-button>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>支付key证书</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wxapp.cert_key" placeholder="请填写名称" style="width:77%"></el-input>
                <el-upload
                  style="width: 14%;float: right;margin: 4px 30px 0 0;"
                  :action="upload"
                  :headers="heads"
                  :on-success="UP_Success4">
                  <el-button size="small" type="primary">选择文件</el-button>
                </el-upload>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="is_info == 2">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>APPID</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechat.app_id" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>AECRET</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechat.app_secret" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>支付商户号</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechat.mch_id" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>支付cert证书</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechat.cert_client" placeholder="请填写名称" style="width:77%"></el-input>
                <el-upload
                  style="width: 14%;float: right;margin: 4px 30px 0 0;"
                  :action="upload"
                  :headers="heads"
                  :multiple="false"
                  :on-success="UP_Success2">
                  <el-button size="small" type="primary">选择文件</el-button>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>支付key证书</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechat.cert_key" placeholder="请填写名称" style="width:77%"></el-input>
                <el-upload
                  style="width: 14%;float: right;margin: 4px 30px 0 0;"
                  :action="upload"
                  :headers="heads"
                  :multiple="false"
                  :on-success="UP_Success1">
                  <el-button size="small" type="primary">选择文件</el-button>
                </el-upload>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="is_info == 3">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>APPID</div>
              <div class="EditNewFormItemIpt">
                <el-input placeholder="请填写名称" v-model="alipay.app_id"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>支付宝公钥</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" placeholder="请填写名称" v-model="alipay.ali_public_key"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>开发者私钥</div>
              <div class="EditNewFormItemIpt">
                <el-input type="textarea" placeholder="请填写名称" v-model="alipay.private_key"></el-input>
              </div>
            </el-form-item>
          </div>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="editPanel = false">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      wxapplist:{},
      wechatlist:{},
      alipaylist:{},
      //小程序配置
      wxapp:{
        app_id:'',
        app_secret:'',
        mch_id:'',
        cert_client:'',
        cert_key:'',
        key:'',
      },
     //公众号配置
      wechat:{
        app_id:'',
        app_secret:'',
        mch_id:'',
        cert_client:'',
        cert_key:'',
        key:'',
      },
      //支付宝配置
      alipay:{
        app_id:'',
        ali_public_key:'',
        private_key:'',
      },
      isShow:1,
      is_info:1,
      activeIndex:'1',
      acndex:1,
      titleMath:'',
      editPanel:false

    }
  },
  mounted() {
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    this.loadTable();
  },
  methods: {
    loadTable() {
      var that = this
      axios({
        method: 'get',
        url: '/admin/general/platform/index',
      }).then(function (res) {
        that.wxapplist =  res.data.data.config.wxapp
        that.wechatlist =  res.data.data.config.wechat
        that.alipaylist =  res.data.data.config.alipay
      })
    },
    //导航选择
    handleSelect_top(e){
      var that = this
      that.activeIndex = e
      if(e == 1){
        that.isShow = 1
      } else if(e == 2){
        that.isShow = 2
      }else if(e == 3){
        that.isShow = 3
      }
    },
    handleSelect2(e){
      var that = this
      that.acndex = e
      if(e == 1){
        that.is_info = 1
      } else if(e == 2){
        that.is_info = 2
      }else if(e == 3){
        that.is_info = 3
      }
    },
    //详情
    headerEdit(){
      var that = this 
      that.editPanel = true
      that.acndex = 1
      that.is_info = 1
      that.titleMath = '编辑'
      axios({
        method: 'get',
        url: '/admin/general/platform/index',
      }).then(function (res) {
        that.wxapp =  res.data.data.config.wxapp
        that.wechat =  res.data.data.config.wechat
        that.alipay =  res.data.data.config.alipay
     
      })
    },
    //编辑
    BtnEdit(){
      var that = this
      let row = {}
      row['wxapp'] = that.wxapp
      row['wechat'] = that.wechat
      row['alipay'] = that.alipay
      axios({
        method: 'post',
        url: '/admin/general/platform/edit',
        data:{
         row 
        }
      }).then(function(res){
        that.$message.success(res.data.msg);
        that.editPanel = false
        that.isShow = 1
        that.activeIndex = 1
        that.loadTable();
      })
    },
    //上传
    UP_Success4 (response, file, fileLis) {
      var that = this
      that.wxapp.cert_client = response.data.url
    },
    UP_Success3 (response, file, fileLis) {
      var that = this
      that.wxapp.cert_key = response.data.url
    },
    UP_Success2 (response, file, fileLis) {
      var that = this
      that.wechat.cert_client = response.data.url
    },
    UP_Success1 (response, file, fileLis) {
      var that = this
      that.wechat.cert_key = response.data.url
    },
  }
}
</script>

<style scoped>
.ThirdPartySettings_I /deep/ .el-upload--text{
  float: right ;
  border: 0;
  width: 90px;
  height: 32px;
}
</style>